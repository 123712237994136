<template>
  <section
    id="features"
    class="my-12"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row          
          justify="center"
          >
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-icon-card
              align="center"
              class="text-h6 font-weight-bold"
              dark
              v-bind="card"
              size="128"

            />
          </v-col>
        </v-row>
        <base-icon-card
          class="mt-6 text-subtitle-1 text-md-h6"
          justify="end"
          dark
          :image="require(`@/assets/sbkts.jpg`)"
          :image_lr="require(`@/assets/sbkts_lr.jpg`)"
          size="300"
          mobileSize="200"
          text="Свидетельство о безопасности конструкции транспортного средства (СБКТС) – документ, который подтверждает, что конструкция конкретного автомобиля отвечает всем необходимым требованиям технического регламента Таможенного союза 018/2011 “О безопасности колесных транспортных средств”"
        />
        <base-icon-card
          class="mt-6 text-subtitle-1 text-md-h6"
          justify="end"
          dark
          :image="require(`@/assets/epts.jpg`)"
          :image_lr="require(`@/assets/epts_lr.jpg`)"
          size="300"
          mobileSize="300"
          text="ЭПТС – это электронный паспорт транспортного средства, в котором содержится вся информация об автомобиле. ЭПТС пришел на смену бумажному носителю, оформляется и хранится в автоматизированной системе электронных паспортов. Он необходим для регистрации ТС в органах ГИБДД"
        />
        <div            
          class="d-flex flex-wrap justify-center"
        >
          <base-btn min-width="25%" class="mt-10" @click="showOrderForm">
            Оставить заявку
          </base-btn>          
        </div>        
      </v-container>
    </v-responsive>
  </section>
</template>

<script>
  import ShowOrder from '@/mixins/show-order'

  export default {
    name: 'SectionFeatures',

    mixins: [ShowOrder],

    data: () => ({
      cards: [
        {
          icon: 'fast',          
          text: 'Выдача СБКТС и ЭПТС в кротчайшие сроки',
        },
        {
          icon: 'save-money',          
          text: 'Самые выгодные условия в Приморском крае',
        },
        {
          icon: 'qualification',          
          text: 'Новое современное оборудование и высококвалифицированные специалисты',
        },        
      ],
    }),    
  }
</script>
